import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  base: "/",
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: () => import("@/views/HomePage.vue"),
    },
    {
      path: "/services",
      name: "ServicesPage",
      component: () => import("@/views/ServicesPage.vue"),
    },
    {
      path: "/branches",
      name: "BranchesPage",
      component: () => import("@/views/BranchesPage.vue"),
    },
    {
      path: "/news",
      // path: "/infos",
      name: "NewsPage",
      component: () => import("@/views/NewsPage.vue"),
    },
    {
      path: "/careers",
      // path: "/news",
      name: "CareersPage",
      component: () => import("@/views/CareersPage.vue"),
    },
  ],
});

Vue.router = router;
export default router;

// import { createRouter, createWebHistory } from 'vue-router';

// const routes = [
//   {
//     path: '/',
//     name: 'HomePage',
//     component: () => import(/* webpackChunkName: "home" */ '@/views/HomePage.vue'),
//   },
//   {
//     path: '/services',
//     name: 'ServicesPage',
//     component: () => import(/* webpackChunkName: "services" */ '@/views/ServicesPage.vue'),
//   },
//   {
//     path: '/branches',
//     name: 'BranchesPage',
//     component: () => import(/* webpackChunkName: "branches" */ '@/views/BranchesPage.vue'),
//   },
//   {
//     path: '/news',
//     name: 'NewsPage',
//     component: () => import(/* webpackChunkName: "news" */ '@/views/NewsPage.vue'),
//   },
// ];

// const router = createRouter({
//   history: createWebHistory(),
//   routes,
//   scrollBehavior() {
//     return { top: 0, left: 0, behavior: 'smooth' };
//   },
// });

// export default router;
